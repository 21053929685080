<template>
  <div class="lead-item-with-border-row">
    <div class="lead-item-theme" :style="{ backgroundImage: `url(${item.headImg?.[0]})` }"
      @click="handleClick">
      <div class="tag">
        Popular
      </div>
      <h3 class="lead-item-title">
        {{ item.title }}
      </h3>
      <div class="shadow" />
      <div class="lead-item-content">
        {{ item.channel }} · {{ $global.timeAgo($global.dateStringToTimestamp(item.published)) }}
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/module/lead/theme_10.scss';

export default {
  props: {
    item: {
      require: true,
      type: Object,
      default () {
        return {}
      }
    },
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
